import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Seo from "../Seo";
import "./Questions.scss";

class Questions extends Component {
  render() {
    return (
    <div className="container help-center">
    <Seo 
      title="Ajuda / FAQ"
      descriptions="Perguntas frequentes de ajuda."
      />
    <h1>Ajuda / FAQ</h1>
  <div class="wrapper">

  <div class="lista-item">
    <input class="trigger-input" id="faq-titulo-11" type="checkbox" />
    <div class="trigger-wrapper">
      <label for="faq-titulo-11">
        <h2 class="faq-titulo">O que é DONFLIX?</h2>
      </label>
      <p class="faq-conteudo">
      <span style={{'color': '#4784ff'}}><b>DONFLIX</b></span> é uma biblioteca de filmes, séries e animes online. Aqui podes transmitir conteúdo no teu smartphone, Tablet, Computador e TV sem precisar de sair de casa.</p>
    </div>
  </div>

  <div class="lista-item">
    <input class="trigger-input" id="faq-titulo-1" type="checkbox" />
    <div class="trigger-wrapper">
      <label for="faq-titulo-1">
        <h2 class="faq-titulo">É seguro assistir?</h2>
      </label>
      <p class="faq-conteudo">
     O serviço é completamente seguro, não é necessário conta(opcional), nem de fazer download e muito menos iremos pedir por dados pessoais.</p>
    </div>
  </div>
  
  <div class="lista-item">
    <input class="trigger-input" id="faq-titulo-2" type="checkbox" />
    <div class="trigger-wrapper">
      <label for="faq-titulo-2"><h2 class="faq-titulo">Como o DONFLIX funciona?</h2></label>
      <p class="faq-conteudo">
       O nosso serviço vai buscar automaticamente todo o conteúdo existente da internet e organiza como um catálago para melhor organização para que o visitante tenha fácil acesso.</p>
    </div>
  </div>
  
  <div class="lista-item">
    <input class="trigger-input" id="faq-titulo-3" type="checkbox" />
    <div class="trigger-wrapper">
      <label for="faq-titulo-3">
        <h2 class="faq-titulo">Publicidade</h2>
      </label>
      <p class="faq-conteudo">A publicidade é um dos pilares do DONFLIX. Graças a ela conseguimos manter o serviço estável e online. No entanto, estamos empenhados em manter, na medida do possível, os anúncios menos intrusivos para que tenhas uma experiência positiva.</p>
    </div>
  </div>

  <div class="lista-item">
    <input class="trigger-input" id="faq-titulo-4" type="checkbox" />
    <div class="trigger-wrapper">
      <label for="faq-titulo-4">
        <h2 class="faq-titulo">Vídeo muito lento / Não carrega</h2>
      </label>
      <p class="faq-conteudo">Se o vídeo não carregar ou estiver lento, atualiza a página e tenta denovo. Caso o problema persistir, aguarde uns minutos e tente novamente.</p>
    </div>
  </div>


  <div class="lista-item">
    <input class="trigger-input" id="faq-titulo-5" type="checkbox" />
    <div class="trigger-wrapper">
      <label for="faq-titulo-5">
        <h2 class="faq-titulo">Conteúdo sem legendas?</h2>
      </label>
      <p class="faq-conteudo">Caso seja um novo episódio/filme, as legendas podem não estar disponíveis ainda! Em outras palavras, elas ainda não foram criadas. Como regra, as legendas dos episódios sempre saem uma semana após a data de exibição original.</p>
    </div>
  </div>



  <div class="lista-item">
    <input class="trigger-input" id="faq-titulo-6" type="checkbox" />
    <div class="trigger-wrapper">
      <label for="faq-titulo-6">
        <h2 class="faq-titulo">Legendado/Dublado</h2>
      </label>
      <p class="faq-conteudo">
      Só há legendado/dublado se esta opção estiver na escolha do player.
	  <br/>
	  Caso a opção dublada não funcionar, significa que esta versão ainda não está disponível na internet, então você deve aguardar.
      </p>
    </div>
  </div>

  <div class="lista-item">
    <input class="trigger-input" id="faq-titulo-8" type="checkbox" />
    <div class="trigger-wrapper">
      <label for="faq-titulo-8">
        <h2 class="faq-titulo">Disclaimer</h2>
      </label>
      <p class="faq-conteudo">
     <span style={{'color': '#4784ff'}}><b>DONFLIX</b></span> não armazena nenhum tipo de filme ou episódio. Todo o conteúdo são fornecidos por sites de terceiro. Qualquer problema contacte os respetivos administradores.</p>
    </div>
  </div>

  <div class="lista-item">
    <input class="trigger-input" id="faq-titulo-7" type="checkbox" />
    <div class="trigger-wrapper">
      <label for="faq-titulo-7">
        <h2 class="faq-titulo">Junta-te a nós no instagram!</h2>
      </label>
      <p class="faq-conteudo">Segue-nos:<br />
      <div style={{'color': '#4784ff'}}>
         <a href="https://instagram.com/donflix_ga" target="_">@donflix_ga</a>
       </div>
       </p>
    </div>
  </div>
</div>
      </div>
    );
  }
}

export default withRouter(Questions);
